<template>
    <ul
        :class="{ menu: !isSideNav, nav: isSideNav }"
    >
        <router-link
            v-if="!!profile_id"
            :to="{ name: 'profile' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'profile' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("My Profile") }}</span>
            </base-link>
        </router-link>
        <li v-if="isSideNav" class="divider"></li>
        <router-link
            v-if="!!profile_id && isSideNav"
            :to="{ name: 'home' }"
            tag="li"
            active-class="active"
            exact
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'home' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Dashboard") }}</span>
            </base-link>
        </router-link>
        <router-link
            v-if="!!profile_id && isSideNav"
            :to="{ name: 'activity.list' }"
            tag="li"
            active-class="active"
            exact
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'activity.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Activities") }}</span>
            </base-link>
        </router-link>
        <router-link
            v-if="!!profile_id && isSideNav"
            :to="{ name: 'resource.list' }"
            tag="li"
            active-class="active"
            exact
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'resource.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Resources") }}</span>
            </base-link>
        </router-link>
        <li
            v-if="is_at_least_administrator"
            class="divider"
            :data-content="$root.translate('Administration')"
        ></li>
        <router-link
            v-if="is_at_least_administrator"
            :to="{ name: 'activity.list' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'activity.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Activities") }}</span>
            </base-link>
        </router-link>
        <router-link
            v-if="is_at_least_administrator"
            :to="{ name: 'user.list' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'user.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Users") }}</span>
            </base-link>
        </router-link>
        <router-link
            v-if="is_at_least_administrator"
            :to="{ name: 'group.list' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'group.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Group:::Groups", {}, 2) }}</span>
            </base-link>
        </router-link>
        <router-link
            v-if="is_at_least_administrator"
            :to="{ name: 'coupon.list' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'coupon.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Coupons") }}</span>
            </base-link>
        </router-link>
        <!--
        <router-link
            v-if="is_at_least_administrator"
            :to="{ name: 'plan.list' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'plan.list' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Subscription Plans") }}</span>
            </base-link>
        </router-link>
        //-->
        <router-link
            v-if="is_at_least_administrator"
            :to="{ name: 'settings' }"
            tag="li"
            active-class="active"
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            @click.native="$emit('navigate', false)"
        >
            <base-link
                :href="{ name: 'settings' }"
                @click.prevent.stop
            >
                <span>{{ $root.translate("Settings") }}</span>
            </base-link>
        </router-link>
        <li class="divider"></li>
        <li
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
            class="text-center"
        >
            <language-picker
                :block="true"
            />
        </li>
        <li class="divider"></li>
        <li
            :class="{ 'menu-item': !isSideNav, 'nav-item': isSideNav }"
        >
            <a
                href="#"
                @click.prevent.stop="logout"
            >
                <span>{{ $root.translate("Logout") }}</span>
                <open-icon glyph="sign-out-alt" class="float-right mt-1" />
            </a>
        </li>
    </ul>
    <!--
        <ul class="nav">

            <li
                v-if="installable"
                class="nav-item"
            >
                <base-link
                    href="#"
                    @click.prevent="$emit('install')"
                >
                    <open-icon glyph="arrow-circle-down" size="1x" />
                    <span>{{ $root.translate("Install") }}</span>
                </base-link>
            </li>
        </ul>
    //-->
</template>

<script>
/**
 * profile
 * --
 * IF SIDE NAV
 * Dashboard
 * Students
 * Resources
 * IF ADMIN
 * Users
 * Subscription Plans
 * Settings
 * --
 * Report a bug
 * --
 * Logout
 * --
 * Install
 * --
 * Lang picker
 *
 * import { profile_utilities, misc_utilities, string_utilities } from "./nibnut/mixins"

import { BaseImage, BaseLink, OpenIcon } from "./nibnut/components"
 */
import { profile_utilities } from "@/nibnut/mixins"

import { BaseLink, OpenIcon } from "@/nibnut/components"
import { LanguagePicker } from "@/custom/components"

export default {
    name: "AppNavigation",
    mixins: [profile_utilities],
    components: {
        BaseLink,
        OpenIcon,
        LanguagePicker
    },
    methods: {
        logout () {
            this.$emit("logout")
        }
    },
    props: {
        isSideNav: {
            type: Boolean,
            default: false
        },
        installable: {
            type: Boolean,
            required: true
        }
    }
}
</script>
