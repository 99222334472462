<template>
    <div
        v-if="!!message && !!message.message"
        :class="{ [`toast-${message.type}`]: true }"
        class="toast"
        @click="$emit('click')"
    >
        <default-button
            :label="$root.translate('Dismiss')"
            color="clear"
        >
        </default-button>
        <div>
            <div>{{ message.message }}</div>
        </div>
        <progress
            v-if="!!countdown"
            :value="countdown"
            :max="message.dismiss_after"
            class="progress"
        ></progress>
    </div>
</template>

<script>
import { DefaultButton } from "./nibnut/components"

export default {
    components: {
        DefaultButton
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        countdown: {
            validator: prop => !prop || (typeof prop === "number")
        }
    }
}
</script>
