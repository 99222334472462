<template>
    <div
        :class="{ compact: compact_subnav }"
        class="header print-hide"
    >
        <header
            v-if="is_student_page"
            class="navbar semi-responsive"
            v-nibnut-on-scroll="scrolled"
        >
            <router-link
                :to="{ name: 'learn.profile' }"
                tag="section"
                :class="{ 'd-invisible': !profile_id }"
                class="navbar-section avatars-container"
            >
                <div class="student-avatar">
                    <img v-if="group_user.image_url" :src="group_user.image_url" />
                    <span>{{ profile.first_name }}</span>
                </div>
                <div class="group-avatar">
                    <img v-if="group_user.group_image_url" :src="group_user.group_image_url" />
                    <span class="hide-sm">{{ group_user.group_name }}</span>
                </div>
            </router-link>
            <section class="navbar-center">
                <base-link
                    :href="{ name: 'home' }"
                    class="app-logo-link"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo />
                </base-link>
            </section>
            <div class="nav-buttons">
                <base-link
                    :href="{ name: 'learn.home' }"
                    :exact="true"
                    :title="$root.translate('Games')"
                    class="btn-student btn-games"
                >
                    <div class="btn-image"></div>
                    <span>{{ $root.translate("Games") }}</span>
                </base-link>
                <base-link
                    :href="{ name: 'learn.videos' }"
                    :title="$root.translate('Videos')"
                    class="btn-student btn-videos"
                >
                    <div class="btn-image"></div>
                    <span>{{ $root.translate("Videos") }}</span>
                </base-link>
                <base-link
                    :href="{ name: 'learn.glossaries' }"
                    :title="$root.translate('Glossary')"
                    class="btn-student btn-glossary"
                >
                    <div class="btn-image"></div>
                    <span>{{ $root.translate("Glossary") }}</span>
                </base-link>
            </div>
            <section
                :class="{ 'd-invisible': !profile_id }"
                class="navbar-section"
            >
                <default-button
                    flavor="link"
                    :title="$root.translate('Logout')"
                    class="btn-logout"
                    @click.prevent="logout"
                >
                    <div class="mb-2">
                        <open-icon glyph="times" size="2x" />
                    </div>
                    {{ $root.translate('Logout') }}
                </default-button>
            </section>
        </header>
        <header
            v-else
            class="navbar"
        >
            <section
                :class="{ 'd-invisible': !profile_id && !isPublicPage }"
                class="navbar-section"
            >
                <base-link
                    :href="{ name: 'home' }"
                    class="app-logo-link"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo :with-text="false" />
                </base-link>
                <base-link
                    v-if="!!setting('application_name')"
                    :href="{ name: 'home' }"
                    class="btn btn-link hide-sm app-name"
                    @click.native="$emit('navigate', false)"
                >
                    {{ setting('application_name') }}
                </base-link>
            </section>
            <section v-if="!!profile_id" class="navbar-center">
                <base-link
                    v-if="!!setting('application_name')"
                    :href="{ name: 'home' }"
                    class="btn btn-link show-sm app-name"
                    @click.native="$emit('navigate', false)"
                >
                    {{ setting('application_name') }}
                </base-link>
                <label class="form-label hide-sm">
                    {{ $root.translate("Bonjour {name}!", { name: profile.first_name }) }}
                </label>
            </section>
            <section
                :class="{ 'd-invisible': !profile_id && !isPublicPage }"
                class="navbar-section"
            >
                <default-button
                    v-if="setting('bug_reporter_active') && (!!profile_id || isPublicPage)"
                    flavor="link"
                    class="mr-4 hide-sm"
                    @click.prevent="bug_report"
                >
                    <open-icon glyph="bug" />
                </default-button>

                <default-button
                    v-if="!profile_id"
                    flavor="link"
                    :title="$root.translate('Login/Signup')"
                    @click.prevent="login"
                >
                    <open-icon glyph="sign-in-alt" />
                </default-button>
                <div
                    v-if="!!profile_id"
                    ref="navigation"
                    :class="{ 'active': menu_shown }"
                    class="dropdown dropdown-right hide-sm"
                >
                    <base-link
                        href="#"
                        class="btn btn-link dropdown-toggle"
                        tabindex="0"
                        @click="toggle_dropdown_menu"
                    >
                        <figure class="avatar">
                            <open-icon v-if="!profile_id||(!!profile&&!profile.avatar)" glyph="user" size="2x" />
                            <img v-else :src="profile.avatar" :alt="profile.name">
                            <open-icon glyph="caret-down" size="xs" />
                        </figure>
                    </base-link>
                    <app-navigation
                        :installable="installable"
                        @logout="logout"
                        @navigate="navigate"
                    />
                </div>
                <base-link
                    v-if="!!profile_id"
                    href="#"
                    class="btn btn-link show-sm"
                    @click.prevent="$emit('navigate')"
                >
                    <figure class="avatar">
                        <open-icon v-if="!profile_id||(!!profile&&!profile.avatar)" glyph="user" size="2x" />
                        <img v-else :src="profile.avatar" :alt="profile.name">
                    </figure>
                </base-link>
            </section>
        </header>
        <header
            v-if="!!profile_id && is_student_page"
            class="sub-nav"
        >
        </header>
        <header
            v-else-if="!!profile_id"
            :class="{ compact: compact_subnav }"
            class="sub-nav hide-sm"
            v-nibnut-on-scroll="scrolled"
        >
            <base-link
                v-if="!!profile_license && !!profile_license.expires_in"
                :href="{ name: 'profile' }"
                class="subscription-ends-soon bg-error"
            >
                {{ $root.translate("Your subscription ends tomorrow:::Your subscription ends in {number} days. (Click to restart your subscription)", { number: profile_license.expires_in }, profile_license.expires_in) }}
            </base-link>
            <div class="d-flex">
                <div
                    v-for="tab in subnav_tabs"
                    :key="tab.id"
                >
                    <base-link
                        :href="tab.route"
                        :exact="true"
                        class="btn btn-link btn-block"
                        @click.prevent="subnav_tab_id = tab.id"
                    >
                        <open-icon
                            :glyph="tab.glyph"
                            size="3x"
                        />
                        <span>{{ tab.title }}</span>
                    </base-link>
                </div>
            </div>
        </header>
        <div
            v-if="unknown_ip && !is_student_page"
            class="toast toast-error text-center"
        >
            {{ $root.translate("New network address detected: are you currently on your school's network?") }}
            <help-button
                size="sm"
                color="light"
                class="popover-bottom"
            >
                <p>{{ $root.translate("We use this information to determine if your students are using Sam Amuse at school or not.") }}</p>
            </help-button>
            <div>
                <default-button
                    flavor="link"
                    color="light"
                    class="mr-2"
                    @click.prevent="save_profile_ip('invalid_ips')"
                >
                    {{ $root.translate("Other Network") }}
                </default-button>
                <default-button
                    flavor="link"
                    color="light"
                    class="ml-2"
                    @click.prevent="save_profile_ip('valid_ips')"
                >
                    {{ $root.translate("School Network") }}
                </default-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { addl_profile_utilities } from "./custom/mixins"

import { AppLogo, DefaultButton, BaseLink, HelpButton, OpenIcon } from "./nibnut/components"
import AppNavigation from "./AppNavigation"

export default {
    mixins: [addl_profile_utilities],
    components: {
        AppLogo,
        DefaultButton,
        BaseLink,
        HelpButton,
        OpenIcon,
        AppNavigation
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        auto_close_dropdown_menu (event) {
            if(!!event && !!event.target && !!this.$refs.navigation && !this.$refs.navigation.contains(event.target)) {
                this.toggle_dropdown_menu()
            }
        },
        toggle_dropdown_menu () {
            if(this.menu_shown) {
                this.menu_shown = false
                document.removeEventListener("click", this.auto_close_dropdown_menu)
            } else {
                document.addEventListener("click", this.auto_close_dropdown_menu)
                this.menu_shown = true
            }
        },
        scrolled ({ scroll_position }) {
            if(this.is_student_page) { // 370
                if(!this.compact_subnav) this.compact_subnav = scroll_position.y > 200
                else if(this.compact_subnav) this.compact_subnav = scroll_position.y > 150
            } else {
                if(!this.compact_subnav) this.compact_subnav = scroll_position.y > 200
                else if(this.compact_subnav) this.compact_subnav = scroll_position.y > 110
            }
        },
        navigate (navigate) {
            this.toggle_dropdown_menu()
            this.$emit("navigate", navigate)
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        logout () {
            this.toggle_dropdown_menu()
            setTimeout(() => {
                const is_student = this.is_student
                this.$store.dispatch("LOGOUT").then(() => {
                    if(is_student) this.$router.replace({ name: "learn.home" })
                })
            }, 150)
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        is_student_page () {
            return !!this.$route && !!this.$route.meta && !!this.$route.meta.student_route
        },
        subnav_tabs () {
            return [
                { id: "dashboard", title: this.$root.translate("Dashboard"), glyph: "tachometer-alt", route: { name: "home" } },
                { id: "activities", title: this.$root.translate("Activities"), glyph: "gamepad", route: { name: "activity.list" } },
                { id: "resources", title: this.$root.translate("Resources"), glyph: "book", route: { name: "resource.list" } }
            ]
        },
        group_user () {
            const current_group_user_id = parseInt(this.$cookie.get("current_group_user_id"))
            if(this.profile_id && current_group_user_id) {
                const group_user = this.entity_record("group_user", current_group_user_id)
                if(group_user && (group_user.role === this.constants("group_user_types", "ROLE_STUDENT").id)) return group_user
            }
            return {}
        }
    },
    props: {
        isPublicPage: {
            type: Boolean,
            default: false
        },
        installable: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            menu_shown: false,
            compact_subnav: false,
            subnav_tab_id: "dashboard"
        }
    }
}
</script>

<style lang="scss">
@import "./assets/sass/variables";

.header {
    position: sticky;
    flex: 0 0 100%;
    top: 0;
    z-index: $zindex-4;

    & > header {
        position: relative;
        padding: $layout-spacing-lg;

        a, a:active, a:not(.hover-disabled):hover, a:focus, a:visited {
            color: $primary-color;
            text-decoration: none;
        }

        .btn.btn-link {
            border: 0;

            &, &:focus, &:active, &.active, &:not(.hover-disabled):hover, &:visited {
                color: $light-color;
            }
        }
    }
    & > header.navbar {
        background-color: $primary-color;
        color: $light-color;

        $logo-size: 3rem;
        a.app-logo-link {
            position: absolute;
            display: block;
            height: $logo-size;
            top: 50%;
            transform: translate(0, -54%);

            img.app-logo {
                width: 100%;
                height: 100%;
            }
        }
        .app-name {
            margin-left: $logo-size + $layout-spacing-lg;
            &, &:active, &:hover, &:focus, &:visited { color: $light-color; }

            &.show-sm {
                margin-left: 0;
                font-size: clamp(0.6rem, 4vw, 0.8rem);
            }
        }
        .avatar {
            margin-top: (0 - $unit-1);
        }
        .btn.btn-link {
            i.las:not(.la-xs):not(.la-2x) {
                font-size: 1.2rem;
            }
        }
        .dropdown {
            color: $primary-color;

            .menu {
                max-height: 75vw;
                transform: translateY($unit-4);

                a, a:active, a:not(.hover-disabled):hover, a:focus, a:visited {
                    color: $primary-color;
                }
            }
            &:not(.active) .dropdown-toggle:focus + .menu {
                display: none;
            }
        }

        .navbar-section:first-child {
            padding-left: 0.3rem;
        }
        @media(max-width:$size-sm) {
            .avatar {
                margin-top: $unit-1 / -2;
            }
        }
    }
    & > header.sub-nav {
        background-color: $body-bg;
        padding-top: 0;
        padding-bottom: 0;
        @include shadow-variant(0.015rem);

        & > .subscription-ends-soon {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            padding: $layout-spacing-sm / 2;
            text-align: center;

            &, &:focus, &:active, &.active, &:not(.hover-disabled):hover, &:visited {
                color: white;
            }
        }
        & > .d-flex {
            justify-content: center;

            & > div {
                flex: 0 1 8rem;
            }
        }

        .btn.btn-link {
            height: auto;
            font-size: 1rem;
            padding-top: $layout-spacing-lg;
            padding-bottom: $layout-spacing-lg;

            &, &:focus, &:active, &.active, &:visited {
                color: #931d76;
            }
            &:not(.hover-disabled):hover {
                color: #f3932c;
            }

            i.las:not(.la-xs):not(.la-2x) {
                display: block;
                margin: $unit-2 auto;
                transition: font-size 0.3s ease-in;
            }
            span {
                display: inline-block;
                margin-bottom: $unit-2;
                transition: margin-bottom 0.3s ease-in;
            }

            &.active {
                &, &:focus, &:active, &.active, &:not(.hover-disabled):hover, &:visited {
                    color: #84ba3c;
                }
            }
        }

        &.compact {
            .btn.btn-link {
                i.las:not(.la-xs):not(.la-2x) {
                    display: inline;
                    margin: 0 $unit-2 0 0;
                    font-size: 1rem;
                }
                span {
                    margin-bottom: 0;
                }
            }

            & > .subscription-ends-soon {
                display: none;
            }
        }
    }
}
.student-content {
    .header {
        background: #f4f8fa; // $light-color url("~@/assets/img/student-banner-bg.png") center center no-repeat;
        // background-size: cover;
        padding: $layout-spacing-lg;
        transition: background-position 0.3s ease-in;

        & > header {
            background-color: transparent;
            color: $primary-color;
            margin: 0 auto;
            padding: $unit-16 0;
            font-weight: 700;
            z-index: 1;
            transition: padding 0.3s ease-in;

            @media(max-width:$size-md) {
                &.semi-responsive {
                    width: 90%;
                }
            }
            @media(max-width:$size-sm) {
                &.semi-responsive {
                    width: 95%;
                }
            }

            .navbar-section {
                display: flex;
                font-size: 26px;
                line-height: 28px;

                img {
                    background-color: $light-color;
                    border: 1px solid $light-color;
                    max-height: 4rem;
                    box-shadow: 4px 4px 15px lighten($gray-color, 10%);
                }

                &:last-child {
                    text-align: right;
                    font-size: 18px;
                }

                &.avatars-container {
                    position: relative;
                    font-size: 0.8rem;
                    line-height: 1rem;
                    cursor: pointer;

                    img {
                        float: left;
                        margin-right: $unit-2;

                        @media(max-width:$size-sm) {
                            max-height: 3rem;
                        }
                    }

                    & > .student-avatar {
                        display: flex;
                        align-items: flex-start;
                    }
                    & > .group-avatar {
                        position: absolute;
                        top: 3rem;
                        left: 3rem;

                        img {
                            max-height: 2rem;
                        }
                        @media(max-width:$size-sm) {
                            top: 2.5rem;
                            left: 2.5rem;
                        }
                    }
                }
            }
            .navbar-center {
                flex: 1 0 auto;
                display: block;

                a.app-logo-link {
                    width: 8rem;
                    height: auto;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -63%);

                    @media(max-width:$size-sm) {
                        width: 6rem;
                        height: 6rem;
                    }
                }
            }
            .btn-logout {
                color: $brand-pink-dark;
                height: auto;

                i.las {
                    background-color: $brand-pink-dark;
                    border-radius: 50%;
                    padding: 0.2rem;
                    color: $light-color;
                }

                @include hover-supported {
                    color: $brand-green;
                    i.las {
                        background-color: $brand-green;
                    }
                }
            }
            & > .nav-buttons {
                position: absolute;
                display: flex;
                justify-content: center;
                left: 0;
                bottom: -100px;
                width: 100%;
                max-width: 100%;
                text-align: center;
                box-shadow: none;
                font-family: 'QuickSand', sans-serif;
                font-size: 30px;
                transition: all 0.3s ease-in;

                .btn-student {
                    flex: 0 0 auto;
                    display: block;
                    margin: 0 $unit-4;

                    & > .btn-image {
                        width: 120px;
                        height: 115px;
                        background: url("~@/assets/img/student-buttons.png");
                    }
                    & > span {
                        color: $brand-pink-dark;
                        margin-top: $layout-spacing-sm;
                    }

                    &.active {
                        & > span { color: $brand-green; }
                    }
                    @include hover-supported {
                        & > span { color: $brand-green; }
                    }

                    &.btn-glossary {
                        & > .btn-image { background-position: -10px -10px; }

                        &.active {
                            & > .btn-image { background-position: -150px -10px; }
                        }
                        @include hover-supported {
                            & > .btn-image { background-position: -150px -10px; }
                        }
                    }
                    &.btn-games {
                        & > .btn-image { background-position: -10px -145px; }

                        &.active {
                            & > .btn-image { background-position: -150px -145px; }
                        }
                        @include hover-supported {
                            & > .btn-image { background-position: -150px -145px; }
                        }
                    }
                    &.btn-videos {
                        & > .btn-image { background-position: -290px -10px; }

                        &.active {
                            & > .btn-image { background-position: -290px -145px }
                        }
                        @include hover-supported {
                            & > .btn-image { background-position: -290px -145px; }
                        }
                    }
                }
                @media(max-width:$size-sm) {
                    font-size: 20px;

                    .btn-student {
                        margin: 0;
                    }
                }
            }
        }
        & > header.sub-nav {
            position: absolute;
            display: flex;
            justify-content: center;
            left: 0;
            top: 100%;
            width: 100%;
            max-width: none;
            height: 6rem;
            background-color: $body-bg;
            box-shadow: none;
            z-index: 0;
            transition: height 0.3s ease-in;
        }

        &.compact {
            background-position-y: 20%;

            & > header {
                padding: $unit-8 0;

                & > .nav-buttons {
                    bottom: -90px;
                    font-size: 18px;
                }
            }
            & > header.sub-nav {
                height: 5rem;
            }
        }
    }
}
</style>
