<template>
    <div
        v-if="badge"
        class="sam-award print-hide"
        @click.prevent="close_and_show_next"
    >
        <div
            :class="{ animate: shown }"
            class="image-container"
        >
            <div v-if="shown">
                <award-badge
                    v-if="!!badge.id"
                    :badge="badge"
                    :awarded-at="award_board.awards[0].awarded_at"
                />
                <div v-else class="animate__animated animate__bounce happy-birthday">
                    {{ $root.translate("Happy Birthday") }}!!
                </div>
                <progress
                    :value="countdown"
                    :max="close_in"
                    class="progress"
                ></progress>
            </div>
            <img
                v-else
                src="@/assets/img/gift.png"
                class="animate__animated animate__repeat-2 animate__tada"
            />
        </div>
    </div>
    <!--
    <div v-if="badge" class="toast toast-award print-hide">
        <div class="image-container animate">
            <award-badge :badge="badge" />
        </div>
        <div class="caption">
            {{ badge.description }}
        </div>
        <default-button
            class="btn-clear"
            @click.prevent="close_and_show_next"
        />
    </div>
    //-->
</template>

<script>
import { mapState } from "vuex"

import { profile_utilities, misc_utilities } from "@/nibnut/mixins"

// import { DefaultButton } from "./nibnut/components"
import { AwardBadge } from "./custom/components"

let wiggle_timer = null
let close_timer = null

export default {
    name: "Awards",
    mixins: [profile_utilities, misc_utilities],
    components: {
        // DefaultButton,
        AwardBadge
    },
    mounted () {
        this.show_next()
    },
    watch: {
        profile_id: "show_next",
        "award_board.last_seen_at": "show_next"
    },
    methods: {
        cancel_timer () {
            if(wiggle_timer) {
                clearTimeout(wiggle_timer)
                wiggle_timer = null
            }
            if(close_timer) {
                clearInterval(close_timer)
                close_timer = null
            }
        },
        show_next () {
            if(this.profile_id && this.award_board.awards.length && !this.badge) {
                this.shown = false
                this.badge = this.constants("badges").find(badge => badge.id === this.award_board.awards[0].badge_id) || { id: 0 }
                wiggle_timer = setTimeout(() => {
                    this.shown = true
                    this.countdown = this.badge.id ? this.close_in : 5000
                    const step = 10
                    close_timer = setInterval(() => {
                        this.countdown -= step
                        if(this.countdown <= 0) this.close_and_show_next()
                    }, step)
                }, 2000) // keep in sinc with animate.css' duration
            }
        },
        close () {
            if(this.badge) {
                if(!this.badge.id) {
                    this.$store.dispatch("RECORD_SAVE", { entity: "user", id: this.profile_id, data: { last_birth_year: (new Date()).getFullYear() } })
                }
                this.award_board.awards.shift()
                this.cancel_timer()
                this.badge = null
            }
        },
        close_and_show_next () {
            this.close()
            this.show_next()
        }
    },
    computed: {
        ...mapState(["award_board"])
    },
    data () {
        return {
            badge: null,
            shown: false,
            countdown: 0,
            close_in: 3000
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.sam-award {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(lighten($gray-color-dark, 5%), 0.8);
    z-index: $zindex-system-messages - 1;

    & > .image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        & > img {
            max-width: 100px;
        }
        .happy-birthday {
            font-size: 5vw;
            font-weight: bold;
            // background-image: linear-gradient(90deg, rgba(245,154,6,1) 0%, rgba(206,116,187,1) 33%, rgba(59,155,209,1) 66%, rgba(133,198,65,1) 100%);
            background-image: linear-gradient(90deg, rgba(208,98,0,1) 0%, rgba(182,0,127,1) 33%, rgba(40,90,116,1) 66%, rgba(59,140,19,1) 100%);
            background-size: 100%;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
        }
        progress {
            color: $brand-orange;
            &::-webkit-progress-value {
                background: $brand-orange;
            }
            &::-moz-progress-bar {
                background: $brand-orange;
            }
        }

        &:before, &:after {
            position: absolute;
            content: '';
            display: block;
            width: 140%;
            height: 100%;
            left: -20%;
            z-index: -1000;
            transition: all ease-in-out 0.5s;
            background-repeat: no-repeat;
        }
        &:before {
            display: none;
            top: -75%;
            background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
            background-image:
                radial-gradient(circle, #ffb300 20%, transparent 20%),
                radial-gradient(circle,  transparent 20%, #dc306f 20%, transparent 30%),
                radial-gradient(circle, #1c68c5 20%, transparent 20%),
                radial-gradient(circle, #7127a8 20%, transparent 20%),
                radial-gradient(circle,  transparent 10%, #34a297 15%, transparent 20%),
                radial-gradient(circle, #259af3 20%, transparent 20%),
                radial-gradient(circle, #c62828 20%, transparent 20%),
                radial-gradient(circle, #00944a 20%, transparent 20%),
                radial-gradient(circle, #f68401 20%, transparent 20%);
        }
        &:after {
            display: none;
            bottom: -75%;
            background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
            background-image:
                radial-gradient(circle, #c62828 20%, transparent 20%),
                radial-gradient(circle, #00944a 20%, transparent 20%),
                radial-gradient(circle,  transparent 10%, #7127a8 15%, transparent 20%),
                radial-gradient(circle, #f68401 20%, transparent 20%),
                radial-gradient(circle, #1c68c5 20%, transparent 20%),
                radial-gradient(circle, #dc306f 20%, transparent 20%),
                radial-gradient(circle, #ffb300 20%, transparent 20%);
        }

        &.animate {
            &:before{
                display: block;
                animation: topBubbles ease-in-out 0.75s forwards;
            }
            &:after{
                display: block;
                animation: bottomBubbles ease-in-out 0.75s forwards;
            }
        }
    }
}
/*
.nibnut {
    & > .toast.toast-award {
        @include toast-variant($gray-color-dark);
        border: $border-width solid $gray-color-dark;
        position: absolute;
        display: flex;
        align-items: center;
        left: auto;
        right: 0;
        top: 0;
        width: auto;
        max-width: 25vw;
        margin-top: $unit-4;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        z-index: $zindex-system-messages + 1;

        @media (max-width: $size-sm) {
            width: 100%;
            max-width: none;
        }

        .image-container {
            flex: 0 1 auto;
            margin-right: $unit-4;
            // transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;

            &:before, &:after {
                position: absolute;
                content: '';
                display: block;
                width: 140%;
                height: 100%;
                left: -20%;
                z-index: -1000;
                transition: all ease-in-out 0.5s;
                background-repeat: no-repeat;
            }
            &:before {
                display: none;
                top: -75%;
                background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
                background-image:
                    radial-gradient(circle, #ffb300 20%, transparent 20%),
                    radial-gradient(circle,  transparent 20%, #dc306f 20%, transparent 30%),
                    radial-gradient(circle, #1c68c5 20%, transparent 20%),
                    radial-gradient(circle, #7127a8 20%, transparent 20%),
                    radial-gradient(circle,  transparent 10%, #34a297 15%, transparent 20%),
                    radial-gradient(circle, #259af3 20%, transparent 20%),
                    radial-gradient(circle, #c62828 20%, transparent 20%),
                    radial-gradient(circle, #00944a 20%, transparent 20%),
                    radial-gradient(circle, #f68401 20%, transparent 20%);
            }
            &:after {
                display: none;
                bottom: -75%;
                background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
                background-image:
                    radial-gradient(circle, #c62828 20%, transparent 20%),
                    radial-gradient(circle, #00944a 20%, transparent 20%),
                    radial-gradient(circle,  transparent 10%, #7127a8 15%, transparent 20%),
                    radial-gradient(circle, #f68401 20%, transparent 20%),
                    radial-gradient(circle, #1c68c5 20%, transparent 20%),
                    radial-gradient(circle, #dc306f 20%, transparent 20%),
                    radial-gradient(circle, #ffb300 20%, transparent 20%);
            }

            &.animate {
                &:before{
                    display: block;
                    animation: topBubbles ease-in-out 0.75s forwards;
                }
                &:after{
                    display: block;
                    animation: bottomBubbles ease-in-out 0.75s forwards;
                }
            }
        }
        .caption {
            flex: 1 1 auto;
            padding-right: 1rem + $layout-spacing;
            text-align: center;
        }
        .btn-clear {
            position: absolute;
            top: $layout-spacing;
            right: $layout-spacing;
        }
    }
}
*/
@keyframes topBubbles {
    0%{
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }
   100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
    }
  }

  @keyframes bottomBubbles {
    0%{
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
   100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
    }
}
</style>
