<template>
    <footer class="print-hide">
        <div class="columns text-small">
            <div class="column col-6 col-sm-12">
                &copy;&nbsp;{{ current_year }}&nbsp;{{ setting("customer_name") }}
            </div>
            <div class="column col-6 col-sm-12">
                {{  $root.translate("App sprouted from a") }}&nbsp;<a href="https://nibnut.com"><base-image :src="nibnut" alt="nibnut" /></a>&nbsp;&bull;&nbsp;v.{{ app_version }}
            </div>
        </div>
    </footer>
</template>

<script>
import { misc_utilities, string_utilities } from "@/nibnut/mixins"

import { BaseImage } from "@/nibnut/components"

import nibnut_logo from "@/assets/img/nibnut.png"

export default {
    name: "AppFooter",
    mixins: [misc_utilities, string_utilities],
    components: {
        BaseImage
    },
    computed: {
        nibnut () {
            return nibnut_logo
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

footer {
    font-size: $font-size-sm;
    padding: $layout-spacing-lg;

    .text-small img {
        height: 0.7em;
    }
    .columns {
        .column:first-child {
            text-align: right;
        }
        .column:last-child {
            text-align: left;
        }
    }
    @media(max-width:$size-sm) {
        .columns {
            .column:first-child,
            .column:last-child {
                text-align: center;
            }
            .column:first-child {
                margin-bottom: $unit-1;
            }
        }
    }
}
</style>
