<template>
    <div
        :class="{ 'student-content': !this.$route || !this.$route.meta || is_student_route, 'full-screen': (!$route || !$route.meta || !!$route.meta.full_screen) }"
        class="nibnut"
    >
        <div class="nibnut-app">
            <app-header
                :is-public-page="is_public_page"
                :installable="!is_installed && installable"
                @navigate="set_navigation"
                @bug-report="reporting_bug = true"
            />
            <div
                class="body"
                v-touch:swipe="swiped"
            >
                <main>
                    <router-view :showing-post-login-screen="showing_post_login_screen"></router-view>
                </main>
                <app-sidenav
                    v-if="!!$route && !!$route.meta && !$route.meta.student_route"
                    :navigating="navigating"
                    :installable="!is_installed && installable"
                    @navigate="set_navigation"
                    @install="maybe_show_installation_prompt"
                />
            </div>
            <app-footer
                v-if="!!$route && !!$route.meta && !$route.meta.student_route"
                :is-public-page="is_public_page"
            />
        </div>

        <bug-reporter
            v-if="reporting_bug"
            :show.sync="reporting_bug"
        />

        <offline v-if="offline" />
        <div v-else-if="bootstraping" class="full_page_loader">
            <div>
                <loader size="lg" />
            </div>
        </div>
        <authentication />

        <modal-dialog
            v-if="!is_student_route && !!app_context && !!app_context.settings"
            id="pwa-install-prompt"
            :show.sync="showing_installation_prompt"
        >
            <template v-slot:title>
                <span class="h5">{{ install_prompt_title }}</span>
            </template>

            <div class="text-center">
                <p class="mb-8">{{ $root.translate("You can add the {application_name} application to your home screen, so it's easy to access:", { application_name: app_context.settings.application_name }) }}</p>
                <div v-if="!!install_event" class="btn_install">
                    <base-link
                        href="#"
                        @click.prevent="install"
                    >
                        {{ $root.translate("Install on my device") }}
                    </base-link>
                </div>
                <p v-else v-html="install_prompt_ios_instructions"></p>
                <p class="text-small mt-8">
                    {{ $root.translate("You can install the app at any time through the navigation menu on the left") }}
                </p>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent="showing_installation_prompt = false"
                    >
                        {{ $root.translate("Not now") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <students-archiver />
        <students-list-editor />

        <system-message
            v-if="is_safari&&!!system_message.message"
            :message="system_message"
            :countdown="message_countdown"
            @click="dismiss_message"
        />
        <transition v-else name="flip" enter-active-class="animated flipInX" leave-active-class="animated flipOutX" mode="out-in">
            <system-message
                v-if="!!system_message.message"
                :message="system_message"
                :countdown="message_countdown"
                @click="dismiss_message"
            />
        </transition>

        <awards />
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { ui_utilities, string_utilities } from "./nibnut/mixins"
import { addl_profile_utilities } from "./custom/mixins"

import AppHeader from "./AppHeader"
import AppFooter from "./AppFooter"
import AppSidenav from "./AppSidenav"
import SystemMessage from "./SystemMessage"
import Awards from "./Awards"
import { Offline, BugReporter } from "./nibnut/dialogs"
import { Authentication, StudentsArchiver, StudentsListEditor } from "./custom/dialogs"
import { Loader } from "./custom/components"
import { ModalDialog, BaseLink, DefaultButton } from "./nibnut/components"

import ios_action_button from "./assets/img/Navigation_Action_2x.png"

let timer = null
/*
const is_mobile_device = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf("IEMobile") !== -1);
};
*/
const is_ios = () => {
    if(!window.MSStream) {
        const user_agent = window.navigator.userAgent
        if(/iphone|ipad|ipod/i.test(user_agent)) {
            const start = user_agent.indexOf("OS ")
            return ((start < 0) || (window.Number(user_agent.substr(start + 3, 3).replace("_", ".")) > 10))
        }
    }
    return false
}
const is_ios_installed = () => {
    return ("standalone" in window.navigator) && window.navigator.standalone
}

export default {
    components: {
        AppHeader,
        AppFooter,
        AppSidenav,
        SystemMessage,
        Awards,
        Offline,
        Authentication,
        StudentsArchiver,
        StudentsListEditor,
        ModalDialog,
        BaseLink,
        DefaultButton,
        Loader,
        BugReporter
        // 'my-component': () => import('./my-async-component')
    },
    mixins: [addl_profile_utilities, ui_utilities, string_utilities],
    created () {
        try {
            this.is_safari = navigator.appVersion && navigator.appVersion.match(/Safari/) && !navigator.appVersion.match(/Chrome/)
            if(this.is_safari) {
                setTimeout(() => {
                    document.body.className += " is-safari"
                }, 500)
            }
        } catch (e) {
        }

        document.addEventListener("pwa-updated", this.app_updated, { once: true })
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if(this.app_updating) return
            this.app_updating = true
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName)
                })
            })
            window.location.reload(true)
        })

        window.addEventListener("beforeinstallprompt", this.bootstrap_installation)
        window.addEventListener("online", this.online_status_changed)
        window.addEventListener("offline", this.online_status_changed)
        window.addEventListener("scroll", this.scrolled)

        this.navigating = !this.small_screen && !this.$cookie.get("hide-navigation")

        document.addEventListener("contextmenu", this.image_copyright)
    },
    mounted () {
        this.load()
    },
    beforeRouteLeave () {
        if(this.small_screen) this.set_navigation(false)
    },
    beforeDestroy () {
        document.removeEventListener("contextmenu", this.image_copyright)

        window.removeEventListener("beforeinstallprompt", this.bootstrap_installation)
        window.removeEventListener("online", this.online_status_changed)
        window.removeEventListener("offline", this.online_status_changed)
        window.removeEventListener("scroll", this.scrolled, false)
    },
    watch: {
        "system_message.message": "system_message_changed",
        "login_request.panel_id": "show_next_post_login_screen"
    },
    methods: {
        image_copyright (event) {
            if(!!event.target && (event.target.nodeName === "IMG")) {
                event.preventDefault()
                event.stopPropagation()
            }
        },
        load () {
            this.$store.dispatch("LOAD_PROFILE").catch(() => {
                // ignore any error
            }).then(() => {
                this.bootstraping = false
            })
        },
        bootstrap_installation (install_event) {
            this.install_event = install_event
        },
        scrolled () {
            this.scrolled_deep = document.documentElement.scrollTop >= window.innerHeight
        },
        set_navigation (navigating = null) {
            if(navigating === null) navigating = !this.navigating
            this.navigating = navigating
            if(this.navigating) this.$cookie.delete("hide-navigation")
            else this.$cookie.set("hide-navigation", true, { expires: "1Y" })
        },
        online_status_changed () {
            this.$store.dispatch("EVALUATE_ONLINE_STATUS")
        },
        show_next_post_login_screen () {
            if(!!this.profile_id && !this.login_request.panel_id) {
                if(!this.installable_manually && !this.$cookie.get("pwa-introduced")) {
                    if(!this.is_student_route) {
                        this.$cookie.set("pwa-introduced", true, { expires: "1Y" })
                        this.showing_installation_prompt = true
                    }
                } else if(this.is_at_least_teacher && this.profile.is_subscribed) {
                    if(this.profile.groups_to_archive && !!this.profile.groups_to_archive.length) {
                        this.$store.dispatch("SHOW_STUDENT_ARCHIVER", { show: true })
                    } else if(this.profile.groups_to_manage && !!this.profile.groups_to_manage.length) {
                        this.$store.dispatch("SHOW_STUDENT_LIST_EDITOR", { show: true })
                    }
                }
            }
        },
        system_message_changed () {
            if(timer) {
                clearInterval(timer)
                timer = null
            }
            if(this.system_message.message) {
                this.message_countdown = this.system_message.dismiss_after || 7000
                const step = 10
                timer = setInterval(() => {
                    this.message_countdown -= step
                    if(this.message_countdown <= 0) {
                        clearInterval(timer)
                        timer = null
                        this.dismiss_message()
                    }
                }, step)
            }
        },
        dismiss_message (event) {
            const system_message = this.system_message
            const system_message_id = system_message ? system_message.message_id : null
            this.$store.dispatch("SYSTEM_MESSAGE", {
                type: "warning",
                message: "",
                dismiss_after: 0,
                message_id: null
            })
            if(!!system_message_id && (system_message_id === "update-app")) {
                this.update_app()
            }
        },
        app_updated (event) {
            this.app_registration = event.detail
            this.$warn(this.$root.translate("A new version of this app is available. Click here to upgrade now."), 30000, "update-app")
        },
        update_app () {
            if(!this.app_registration || !this.app_registration.waiting) return
            this.app_registration.waiting.postMessage({ type: "SKIP_WAITING" })
        },
        maybe_show_installation_prompt () {
            this.set_navigation(false)
            // ** user clicked the button... If we're not on iOS, trigger the browser prompt directly.
            // else show the install instructions dialog
            if(this.installable_manually) this.showing_installation_prompt = true
            else if(this.installable) this.install()
        },
        install () {
            this.showing_installation_prompt = false
            if(!this.install_event) return

            this.install_event.prompt()
            // Wait for the user to respond to the prompt
            this.install_event.userChoice.then(choice => {
                if(choice.outcome === "accepted") {
                    // console.log("User accepted the A2HS prompt")
                } else {
                    // console.log("User dismissed the A2HS prompt")
                }
                this.install_event = null
            })
        },
        swiped (direction) {
            if(!!this.$route && !!this.$route.meta && !this.$route.meta.student_route && ((direction === "left") || (direction === "right"))) this.set_navigation(direction === "right")
        }
    },
    computed: {
        ...mapState(["offline", "system_message", "login_request"]),
        showing_post_login_screen () {
            if(!!this.profile_id && !!this.login_request.panel_id) return true
            if(!this.installable_manually && !this.$cookie.get("pwa-introduced")) return true
            if(this.is_at_least_teacher && this.profile.is_subscribed) {
                if(this.profile.groups_to_archive && !!this.profile.groups_to_archive.length) return true
                if(this.profile.groups_to_manage && !!this.profile.groups_to_manage.length) return true
            }
            return false
        },
        is_student_route () {
            return !!this.$route && !!this.$route.meta && !!this.$route.meta.student_route
        },
        installable () {
            return (is_ios() && !is_ios_installed()) || (!!this.install_event && !this.$cookie.get("do-not-install"))
        },
        is_installed () {
            return !!window.matchMedia("(display-mode: standalone)").matches || is_ios_installed()
        },
        installable_manually () {
            return !this.install_event && is_ios() && !is_ios_installed()
        },
        install_prompt_title () {
            if(!this.install_event) return this.$root.translate("Install on my device")
            return this.$root.translate("How to install on my device")
        },
        install_prompt_ios_instructions () {
            const instructions = this.$root.translate("Simply tap the [image] icon and then select the 'Add to Home Screen' option.")
            const matches = instructions.match(/^(.*?)\[([^\]]+?)\](.*?)$/)
            if(matches) {
                return `${matches[1]}<img src="${ios_action_button}" style="height: 1em;" class="mx-3" />${matches[3]}`
            }
            return instructions
        }
    },
    data () {
        return {
            is_safari: false,
            bootstraping: true,
            install_event: null,
            showing_installation_prompt: false,
            scrolled_deep: false,
            navigating: true,
            message_countdown: 0,

            reporting_bug: false,

            app_updating: false,
            app_registration: null
        }
    },
    metaInfo () {
        const application_name = this.setting("application_name")
        if(!this.title) {
            return {
                title: application_name
            }
        }
        return {
            title: this.title,
            titleTemplate: `%s | ${application_name}`
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

.nibnut {
    & > .nibnut-app {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-color: #f4f8fa;

        & > div.body {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            margin: $unit-10 $unit-10 0 $unit-10;

            & > main {
                display: flex;
                flex-direction: column;
                flex: 1 0 auto;
            }
        }
    }
    @media (max-width: $size-sm) {
        & > .nibnut-app {
            & > div.body {
                margin: $unit-3 $unit-3 0 $unit-3;

                & > main {
                    position: relative;
                    left: 0;
                }
            }
        }
    }
    @media print {
        & > .nibnut-app {
            background-color: transparent;
        }
    }

    & > .full_page_loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: $bg-color;
        // transition: all 0.3s ease-in-out;
        z-index: $zindex-loader;

        & > div {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            & > img {
                max-height: 5rem;
                margin: 0 auto $unit-8  auto;
            }
            & > .loader {
                position: relative;
                display: inline-block;
            }
        }
    }
    & > .toast {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: $top-nav-height;
        z-index: $zindex-system-messages;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100%;
            overflow-y: auto;
        }

        progress {
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -1px;
            width: auto;
            color: $light-color;

            &::-webkit-progress-bar {
                background: transparent;
            }
            &::-webkit-progress-value {
                background: $light-color;
            }
            &::-moz-progress-bar {
                background: $light-color;
            }
        }
        .btn-clear {
            position: absolute;
            top: 50%;
            right: $control-padding-x-lg;
            transform: translate(0, -50%);
        }

        &.toast-primary progress {
            background: $primary-color;
        }
        &.toast-success progress {
            background: $success-color;
        }
        &.toast-warning progress {
            background: $warning-color;
        }
        &.toast-error progress {
            background: $error-color;
        }
    }

    &.student-content {
        font-weight: 500;
        font-size: 26px;

        .btn:not(.input-group-btn) {
            border-radius: 0.5em;
        }
        .lar, .las, .lab {
            // font-size: 1em;
        }

        & > .nibnut-app {
            background-color: $light-color;
            color: $primary-color;

            & > div.header {
                padding: 0;
            }
            & > div.body {
                margin: 8rem 4rem 4rem 4rem;

                & > main {
                    padding: 0;
                }

                @media (max-width: $size-sm) {
                    width: 95%;
                    margin: 8rem auto 4rem auto;
                }
            }
        }
    }
    &.full-screen {
        & > .nibnut-app {
            & > div.header,
            .page-footer {
                display: none;
            }
            & > div.body {
                margin: 0;

                .page-content-box {
                    padding: 0;
                }
            }
        }
    }
}
</style>
