<template>
    <nav
        :class="{ active: navigating }"
        class="nibnut-sidenav print-hide show-sm"
        v-touch:swipe="swiped"
    >
        <app-navigation
            :is-side-nav="true"
            :installable="installable"
            @navigate="$emit('navigate', $event)"
            @logout="logout"
        />
    </nav>
</template>

<script>
import AppNavigation from "./AppNavigation"

export default {
    components: {
        AppNavigation
    },
    methods: {
        swiped (direction) {
            if((direction === "left") || (direction === "right")) this.$emit("navigate", (direction === "right"))
        },
        logout () {
            this.$store.dispatch("LOGOUT")
        }
    },
    props: {
        navigating: {
            type: Boolean,
            required: true
        },
        installable: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut {
    & > .nibnut-app {
        & > div.body {
            $nav-width: 0;
            $nav-active-width: 100%;
            $nav-max-width: none;
            & > nav.nibnut-sidenav {
                position: fixed;
                top: $top-nav-height;
                right: 0;
                bottom: 0;
                width: $nav-width;
                max-width: $nav-max-width;
                background-color: $body-bg;
                padding: $layout-spacing 0;
                overflow: hidden;
                transition: width 0.3s ease-in-out;
                z-index: $zindex-2;

                & > .nav {
                    min-height: 100%;
                    margin: 0;

                    & > .nav-item {
                        padding: $layout-spacing-sm;
                        margin: 0;
                        border-left: 0.25rem solid $body-bg;
                        background-color: $body-bg;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;

                        & > a {
                            & > i {
                                font-size: 1rem;
                            }
                            & > span {
                                display: inline;
                            }
                        }

                        &.active {
                            border-left-color: $primary-color;
                        }
                    }
                }

                &.active {
                    width: $nav-active-width;
                }
            }
        }
    }
}
</style>
